<template>
  <div class="full-page">
    <Load v-if="isLoading" />

    <template v-if="campaign">
      <CustomerInformationForm />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import colorBnn from '@/config/color'
import loadCampaign from '@/utils/loadcampaign'

export default {
  name: 'CustomerInformation',

  components: {
    CustomerInformationForm: () =>
      import('@/components/bnn/CustomerInformationForm'),
    Load: () => import('@/views/Load'),
  },

  computed: {
    ...mapState({
      campaign: state => state.campaign.current,
      isLoading: state => state.campaign.isLoading,
    }),
  },

  data() {
    return {
      // Import
      colorBnn,

      // Data
    }
  },

  async mounted() {
    if (this.campaign) {
      return
    }

    const active = await loadCampaign(this.campaign)

    if (active) {
      this.$router.push({
        name: 'ProductDetail',
        params: {
          slug: this.$route?.params?.slug,
        },
      })
    }

    if (this.campaign?.data?.redirect_url) {
      window.location.href = this.campaign?.data?.redirect_url
    }
  },

  methods: {},
}
</script>

<style lang="stylus" scoped>

 .h-full {
	margin-top: 80px;
	min-height: 100dhv;
	display: flex;
	align-items: center;
	justify-content: center;
 }

 .hr-dash {
	overflow: hidden;
	position: relative;
	text-align: center;
	padding: 10px;
	margin-bottom: 20px;
	width: 50%;
 }

 .hr-dash:before {
	content: '';
	position: absolute;
	border-bottom: 5px dashed $color-gray-200;
	top: -3px;
	bottom: -3px;
	left: -3px;
	right: -3px;
 }

 .text-price-md
    font-size: 1rem
.text-price-lg
    font-size: 1.125rem
    font-weight: bold
    text-overflow: ellipsis

::v-deep .v-input input[type="number"]
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button
    -webkit-appearance: none;
    -moz-appearance: textfield;
</style>
